import React from 'react';
import cn from 'classnames';
import { Link } from '@components/containers';

import styles from './MenuItem.module.scss';
import Props from './MenuItem.props';

const MenuItem = ({ selected, children, className, innerClassName, ...linkProps }: Props) => {
  return (
    <li className={cn(styles.Wrapper, className)}>
      <Link openInNewTab={false} className={styles.Link} {...linkProps}>
        <div className={cn(styles.MenuItem, { [styles.selected]: selected }, innerClassName)}>
          {children}
        </div>
      </Link>
    </li>
  );
};

export default MenuItem;
