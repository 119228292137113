import React from 'react';
import cn from 'classnames';

import TextLink from '@components/ui/TextLink/TextLink';
import Card from '@components/ui/Card/Card';

import styles from './SubMenu.module.scss';
import Props from './SubMenu.props';
import { useEscapeListener } from '@hooks/useEscapeListener';

const SubMenu = ({ links, isOpen = false, triggerClose, className }: Props) => {
  !!triggerClose && useEscapeListener(triggerClose);

  return (
    <li className={cn(styles.Wrapper, { [styles.Open]: isOpen })}>
      <Card className={cn(styles.SubMenu, className)} hasBackground shadowType="md">
        <ul className={styles.Inner}>
          {links.map((link, idx) => (
            <li key={`${link.url}-${idx}`} className={styles.ItemWrapper}>
              <TextLink
                href={link.url}
                onClick={link.onClick}
                className={styles.SubMenuItem}
                theme="tertiary">
                {link.label}
              </TextLink>
            </li>
          ))}
        </ul>
      </Card>
      <div className={cn(styles.Overlay, { [styles.Open]: isOpen })} onMouseOver={triggerClose} />
    </li>
  );
};

export default SubMenu;
