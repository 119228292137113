import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons';

import MenuItem from '../MenuItem/MenuItem';
import SubMenu from '../SubMenu/SubMenu';
import LargeSubMenu from '../LargeSubMenu/LargeSubMenu';

import { useSitelinks, useDestinationLinks, useUnauthorizedRedirector } from '@hooks/index';
import { NavbarTabsTypes, NL_TRAVELOFFERS_DOMAIN } from '@constants/index';
import { useStores } from '@models/RootStore/RootStore';
import SearchButton from '@components/ui/SearchButton/SearchButton';

import styles from './Menu.module.scss';
import Props from './Menu.props';

const Menu = ({ className, selectedTab }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { UserStore } = useStores();
  const siteLinks = useSitelinks();
  const destinationLinks = useDestinationLinks();
  const { handleUnauthorizedRedirect } = useUnauthorizedRedirector();
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);
  const [isDestMenuOpen, setDestMenuOpen] = useState(false);

  const handleOpenSubMenu = (set: any, isOpen: boolean, shouldAuthenticate?: boolean) => {
    if (shouldAuthenticate && !UserStore.isAuthenticated) {
      return handleUnauthorizedRedirect();
    }

    setAccountMenuOpen(false);
    setDestMenuOpen(false);

    set(!isOpen);
  };

  const getDestinationLinks = useCallback(() => {
    return destinationLinks?.destination_links.map((item: any) => ({
      title: {
        label: item.title,
        url: `/${item._slug}`,
      },
      items: item.destinations.map((destination: any) => ({
        label: destination.title,
        url: `/${destination._slug}`,
      })),
      overviewLink: {
        label: t('DOMAIN.DESTINATIONS.ALL_COUNTRIES_FROM_X', { destination: item.title }),
        url: `/${siteLinks?.destinations_overview[0]._slug}?active=${item._slug}`,
      },
    }));
  }, []);

  const handleLogout = () => {
    UserStore.logout();
    router.replace('/');
  };

  const isDP = router.locale === 'nl-NL';

  return (
    <ul className={cn(styles.Menu, className)}>
      {!!getDestinationLinks() && (
        <>
          <MenuItem
            selected={selectedTab === NavbarTabsTypes.DESTINATIONS}
            href={`/${siteLinks?.destinations_overview?.[0]._slug}`}
            onClick={() => handleOpenSubMenu(setDestMenuOpen, true)}
            onHover={() => handleOpenSubMenu(setDestMenuOpen, false)}
            onFocus={() => handleOpenSubMenu(setDestMenuOpen, false)}
            className={styles.MenuItem}>
            {t('NAVIGATION.MENU.DESTINATIONS')}
          </MenuItem>
          <LargeSubMenu
            isOpen={isDestMenuOpen}
            triggerClose={() => setDestMenuOpen(false)}
            lists={getDestinationLinks() || []}
            className={styles.DestSubMenu}
          />
        </>
      )}
      {!!siteLinks?.top_ten_lists?.[0]._slug && (
        <MenuItem
          selected={selectedTab === NavbarTabsTypes.TOPLISTS}
          href={`/${siteLinks?.top_ten_lists?.[0]._slug}`}
          className={styles.MenuItem}
          onHover={() => handleOpenSubMenu(setDestMenuOpen, true)}>
          {t('NAVIGATION.MENU.TOPLISTS')}
        </MenuItem>
      )}
      {isDP && (
        <MenuItem
          selected={selectedTab === NavbarTabsTypes.NONE}
          href={NL_TRAVELOFFERS_DOMAIN}
          className={styles.MenuItem}
          onHover={() => handleOpenSubMenu(setDestMenuOpen, true)}>
          {t('NAVIGATION.MENU.TRAVELOFFERS')}
        </MenuItem>
      )}
      {!!siteLinks?.travelstory_archive?.[0]._slug && (
        <MenuItem
          selected={selectedTab === NavbarTabsTypes.TRAVELSTORIES}
          href={`/${siteLinks?.travelstory_archive?.[0]._slug}`}
          className={styles.MenuItem}
          onHover={() => handleOpenSubMenu(setDestMenuOpen, true)}>
          {t('NAVIGATION.MENU.TRAVELSTORIES')}
        </MenuItem>
      )}
      {!!siteLinks?.wildlife?.[0]._slug && (
        <MenuItem
          selected={selectedTab === NavbarTabsTypes.WILDLIFE}
          href={`/${siteLinks?.wildlife?.[0]._slug}`}
          className={styles.MenuItem}
          onHover={() => handleOpenSubMenu(setDestMenuOpen, true)}>
          {t('NAVIGATION.MENU.WILDLIFE')}
        </MenuItem>
      )}

      {!!siteLinks?.news_archive?.[0]._slug && (
        <MenuItem
          selected={selectedTab === NavbarTabsTypes.NEWS}
          href={`/${siteLinks?.news_archive?.[0]._slug}`}
          className={styles.MenuItem}
          onHover={() => handleOpenSubMenu(setDestMenuOpen, true)}>
          {t('NAVIGATION.MENU.NEWS')}
        </MenuItem>
      )}
      <MenuItem
        onClick={() => handleOpenSubMenu(setAccountMenuOpen, isAccountMenuOpen, true)}
        onFocus={() => handleOpenSubMenu(setAccountMenuOpen, false, true)}
        className={styles.MenuItem}
        innerClassName={styles.Account}
        selected={UserStore.isAuthenticated}>
        <FontAwesomeIcon icon={faUserCircle} />
      </MenuItem>
      <SubMenu
        isOpen={isAccountMenuOpen}
        triggerClose={() => setAccountMenuOpen(false)}
        links={[
          {
            label: siteLinks?.my_account?.[0].title || '',
            url: `/${siteLinks?.my_account?.[0]._slug}`,
          },
          {
            label: siteLinks?.my_bucketlist?.[0].title || '',
            url: `/${siteLinks?.my_bucketlist?.[0]._slug}`,
          },
          {
            label: siteLinks?.my_visited?.[0].title || '',
            url: `/${siteLinks?.my_visited?.[0]._slug}`,
          },
          {
            label: t('DOMAIN.AUTHENTICATION.LOGOUT'),
            onClick: handleLogout,
          },
        ]}
        className={styles.AccountSubMenu}
      />
      {!!siteLinks?.search_page?.[0]._slug && (
        <MenuItem
          className={styles.MenuItem}
          innerClassName={styles.SearchWrapper}
          onHover={() => handleOpenSubMenu(setDestMenuOpen, true)}>
          <SearchButton />
        </MenuItem>
      )}
    </ul>
  );
};

export default observer(Menu);
