import useEventListener from '@use-it/event-listener';

export const useEscapeListener = (toExecute: () => any) => {
  const ESCAPE_KEYS = ['27', 'Escape'];

  const closeMenuListener = ({ key }: KeyboardEvent) => {
    if (ESCAPE_KEYS.includes(key) && toExecute) toExecute();
  };

  useEventListener('keyup', closeMenuListener);
};
