import React, { useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next/';

import { useSitelinks } from '@hooks/useSiteLinks';
import PersonalService from '@services/PersonalService';

import NewsletterSignupForm from '../NewsletterSignupForm/NewsletterSignupForm';
import { NewsletterSignupValues } from '../NewsletterSignupForm/NewsletterSignupForm.props';

import styles from './NewsletterSignupFormWrapper.module.scss';
import Props from './NewsletterSignupFormWrapper.props';

const NewsletterSignupFormWrapper = ({ onSubmit, theme = 'primary', className }: Props) => {
  const { t } = useTranslation();
  const siteLinks = useSitelinks();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = () => {
    setError('');
  };

  const handleSubmit = async (values: NewsletterSignupValues) => {
    setError('');
    setLoading(true);

    try {
      await PersonalService.newsletterSubscribe(values.email);
      toast.success(t('DOMAIN.NEWSLETTER.SUBSCRIPTION_SUCCESS'));

      onSubmit && onSubmit();
    } catch (e) {
      setError(t('DOMAIN.NEWSLETTER.SUBSCRIPTION_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cn(styles.NewsletterSignupFormWrapper, className)}>
      <NewsletterSignupForm
        termsLink={`/${siteLinks?.terms_and_conditions[0]?._slug}`}
        privacyLink={`/${siteLinks?.privacy_statement[0]?._slug}`}
        initialValues={{ email: '', acceptTerms: false }}
        onSubmit={handleSubmit}
        onChange={handleChange}
        isLoading={isLoading}
        error={error}
        theme={theme}
      />
    </div>
  );
};

export default NewsletterSignupFormWrapper;
