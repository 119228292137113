import React, { useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { useSitelinks } from '@hooks/useSiteLinks';

import styles from './SearchOverlay.module.scss';
import Props from './SearchOverlay.props';

const SearchOverlay = ({ isOpen, onCloseClick, className, inputFieldRef }: Props) => {
  const { t } = useTranslation();
  const siteLinks = useSitelinks();
  const router = useRouter();

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    router.push(`/${siteLinks?.search_page[0]._slug}?q=${searchQuery}`);
    onCloseClick();
  };

  const submitListener = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSearch();
  };

  return (
    <div className={cn(styles.SearchOverlay, { [styles.IsOpen]: isOpen }, className)}>
      <div className={cn('container', styles.Container)}>
        <div className={styles.InputWrapper}>
          <input
            ref={inputFieldRef}
            placeholder={`${t('GENERIC.SEARCH')}...`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={styles.Input}
            onKeyUp={submitListener}
          />
          <div className={styles.IconWrapper} onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} className={styles.Icon} />
          </div>
        </div>
        <div className={styles.Close} onClick={onCloseClick}>
          <FontAwesomeIcon icon={faTimes} className={styles.CloseIcon} />
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
