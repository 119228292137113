import React from 'react';
import cn from 'classnames';

import styles from './MobileSubMenu.module.scss';
import Props from './MobileSubMenu.props';
import TextLink from '@components/ui/TextLink/TextLink';

const MobileSubMenu = ({ links, triggerClose, isOpen = false, className }: Props) => {
  const fontSize = 16;
  const itemPadding = 8;

  const height = isOpen ? links.length * (fontSize + 2 * itemPadding) : 0;

  return (
    <div
      className={cn(styles.MobileSubMenu, { [styles.Open]: isOpen }, className)}
      style={{ height }}>
      <ul className={styles.Inner}>
        {links.map((link, idx) => (
          <li key={`${link.url}-${idx}`} className={styles.ItemWrapper}>
            <TextLink
              href={link.url}
              onClick={() => {
                link.onClick && link.onClick();
                triggerClose && triggerClose();
              }}
              className={styles.SubMenuItem}
              theme="tertiary">
              {link.label}
            </TextLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileSubMenu;
