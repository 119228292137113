import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useSitelinks } from './useSiteLinks';
import { useStores } from '@models/RootStore/RootStore';

export const useUnauthorizedRedirector = () => {
  const router = useRouter();
  const sitelinks = useSitelinks();

  const handleUnauthorizedRedirect = () => {
    if (sitelinks?.login_register) {
      router.replace(`/${sitelinks.login_register[0]._slug}`);
    } else {
      router.replace('/');
    }
  };

  return { handleUnauthorizedRedirect };
};

export const useProtectedRoute = () => {
  const { UserStore } = useStores();
  const { handleUnauthorizedRedirect } = useUnauthorizedRedirector();

  useEffect(() => {
    (async () => {
      if (UserStore.hasLoaded) {
        if (!UserStore.isAuthenticated) {
          handleUnauthorizedRedirect();
        }
      }
    })();
  }, [UserStore.hasLoaded, UserStore.isAuthenticated]);
};
