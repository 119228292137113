import React from 'react';
import cn from 'classnames';

import LargeSubMenuList from '../LargeSubMenuList/LargeSubMenuList';
import { useEscapeListener } from '@hooks/useEscapeListener';
import Card from '@components/ui/Card/Card';

import styles from './LargeSubMenu.module.scss';
import Props from './LargeSubMenu.props';

const LargeSubMenu = ({ lists, isOpen = false, triggerClose, className }: Props) => {
  !!triggerClose && useEscapeListener(triggerClose);

  return (
    <li className={cn(styles.LargeSubMenu, { [styles.Open]: isOpen }, className)}>
      <Card innerClassName={styles.Inner} hasBackground>
        {lists.map((list) => (
          <LargeSubMenuList key={list.title.url} {...list} className={styles.SubMenuList} />
        ))}
      </Card>
      <div
        className={cn(styles.Overlay, { [styles.Open]: isOpen })}
        onMouseOver={triggerClose}
        onClick={triggerClose}
      />
    </li>
  );
};

export default LargeSubMenu;
