import React from 'react';
import cn from 'classnames';

import styles from './FooterList.module.scss';
import Props from './FooterList.props';
import TextLink from '@components/ui/TextLink/TextLink';

const FooterList = ({ title, links, className }: Props) => {
  return (
    <div className={cn(styles.FooterList, className)}>
      <p className={styles.Title}>{title}</p>
      <div className={styles.Items}>
        {!!links &&
          links.map(
            (link) =>
              !!link.url && (
                <TextLink
                  key={link.url}
                  href={`/${link.url}`}
                  className={styles.ListItem}
                  theme="secondary">
                  {link.label}
                </TextLink>
              ),
          )}
      </div>
    </div>
  );
};

export default FooterList;
