import React from 'react';
import Image from 'next/image';
import cn from 'classnames';

import { Link } from '../../containers';

import classes from './Card.module.scss';
import Props from './Card.props';

const Card = ({
  children,
  hasBackground = false,
  shadowType = 'sm',
  backgroundType = 'default',
  className,
  innerClassName,
  ...linkProps
}: Props) => {
  const { href, onClick } = linkProps;
  const isClickable = !!href || !!onClick;

  const classNames = cn(
    classes.Card,
    {
      [classes[`shadow-sm`]]: shadowType === 'sm',
      [classes[`shadow-md`]]: shadowType === 'md',
      [classes.WhiteBackground]: !hasBackground,
      [classes.Clickable]: isClickable,
      [classes.AltBackground]: backgroundType !== 'default',
    },
    className,
  );

  const getBackgroundImagePath = () => {
    if (backgroundType === 'red') return require('@assets/images/red-waves.png');

    return require('@assets/images/waves.svg');
  };

  return (
    <Link className={classNames} {...linkProps}>
      {hasBackground && (
        <div className={classes.ImageWrapper}>
          <Image
            src={getBackgroundImagePath()}
            alt=""
            quality={60}
            layout="fill"
            className={classes.Background}
          />
        </div>
      )}
      <div className={cn(classes.Content, innerClassName)}>{children}</div>
    </Link>
  );
};

export default Card;
