import React from 'react';
import cn from 'classnames';
import i18next from 'i18next';

import classes from './NewsletterInput.module.scss';
import Props from './NewsletterInput.props';

const NewsletterInput = ({ error, className, theme = 'primary', ...otherProps }: Props) => {
  return (
    <div className={cn(classes.NewsletterInput, classes[theme], className)}>
      <input type="email" {...otherProps} />
      {!!error && <p className={classes.Error}>{error}</p>}
    </div>
  );
};

NewsletterInput.defaultProps = {
  placeholder: i18next.t('DOMAIN.AUTHENTICATION.EMAIL'),
};

export default NewsletterInput;
