import React from 'react';
import cn from 'classnames';

import styles from './Spinner.module.scss';
import { SpinnerProps } from './Spinner.props';

export const Spinner = ({ color, size = 40, className, ...props }: SpinnerProps) => {
  const dimension = `${size}px`;

  return (
    <div className={cn([styles.Spinner, className])} {...props}>
      <div
        style={{ width: dimension, height: dimension, borderWidth: size / 6, color }}
        className={styles.Loader}
      />
    </div>
  );
};

export default Spinner;
