import React from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next/';

import styles from './NewsletterSignupForm.module.scss';
import Props from './NewsletterSignupForm.props';
import Button from '@components/ui/Button/Button';
import CheckboxInput from '@components/ui/CheckboxInput/CheckboxInput';
import NewsletterInput from '@components/ui/NewsletterInput/NewsletterInput';
import TextLink from '@components/ui/TextLink/TextLink';

const NewsletterSignupForm = ({
  initialValues,
  onSubmit,
  error,
  isLoading,
  termsLink,
  privacyLink,
  theme = 'primary',
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const validationScheme = Yup.object().shape({
    email: Yup.string()
      .email(t('VALIDATION.E_MAIL_INVALID'))
      .required(t('VALIDATION.FIELD_REQUIRED')),
    acceptTerms: Yup.boolean().isTrue(t('VALIDATION.MUST_ACCEPT_TERMS')),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationScheme}
      validateOnBlur={false}
      validateOnChange>
      {({ errors, touched }) => {
        let errorMessage;

        if (error) errorMessage = error;
        if (touched.email && errors.email) errorMessage = errors.email;
        if (touched.acceptTerms && errors.acceptTerms) errorMessage = errors.acceptTerms;

        return (
          <Form
            onChange={onChange}
            className={cn(styles.Form, { [styles.Secondary]: theme === 'secondary' })}>
            <Field name="email">
              {({ field }: FieldProps) => (
                <NewsletterInput
                  placeholder={t('DOMAIN.NEWSLETTER.EMAIL')}
                  className={styles.Email}
                  theme={theme}
                  data-testid="EmailInput"
                  {...field}
                />
              )}
            </Field>

            <Field name="acceptTerms">
              {({ field }: FieldProps) => (
                <CheckboxInput className={styles.Checkbox} data-testid="AcceptTerms" {...field}>
                  {t('GENERIC.I_ACCEPT')}{' '}
                  <TextLink
                    theme={theme === 'secondary' ? 'basic-white' : 'basic'}
                    href={`${termsLink}`}
                    openInNewTab>
                    {t('GENERIC.TERMS_AND_CONDITIONS')}
                  </TextLink>{' '}
                  {t('GENERIC.AND')}{' '}
                  <TextLink
                    theme={theme === 'secondary' ? 'basic-white' : 'basic'}
                    href={`${privacyLink}`}
                    openInNewTab>
                    {t('GENERIC.PRIVACY_STATEMENT')}
                  </TextLink>
                </CheckboxInput>
              )}
            </Field>

            <p
              className={cn(styles.Error, {
                [styles.Show]: !!errorMessage,
              })}
              data-testid="ErrorMessage">
              {errorMessage}
            </p>

            <Button
              theme={theme === 'secondary' ? 'primary-inverted' : 'primary'}
              className={styles.Button}
              isLoading={isLoading}>
              {t('DOMAIN.NEWSLETTER.SIGNUP')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewsletterSignupForm;
