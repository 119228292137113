import React, { useState } from 'react';
import { useRouter } from 'next/router';

import { useSitelinks } from '@hooks/useSiteLinks';

import Props from './SearchForm.props';
import SearchInput from '@components/ui/SearchInput/SearchInput';

const SearchForm = ({ onSearch, ...searchInputProps }: Props) => {
  const router = useRouter();
  const siteLinks = useSitelinks();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    router.push(`/${siteLinks?.search_page[0]._slug}?q=${searchQuery}`);
    onSearch && onSearch();
  };

  return (
    <SearchInput
      value={searchQuery}
      onChange={setSearchQuery}
      onSubmit={handleSearch}
      {...searchInputProps}
    />
  );
};
export default SearchForm;
