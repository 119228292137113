import { useEffect, useState } from 'react';
import Storage from '@helpers/StorageHelper';

export const useNewsHeaderIsOpen = (delay: number = 4000) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const storedState = Storage.getNewsHeaderState();

    setOpen(!!storedState);

    const timer = setTimeout(() => {
      if (storedState === null) {
        setOpen(true);
      }
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    Storage.setNewsHeaderState(isOpen);
  }, [isOpen]);

  return { isOpen, setOpen };
};
