import React from 'react';
import { useTranslation } from 'react-i18next/';

import styles from './SecondaryFooter.module.scss';

const SecondaryFooter = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.SecondaryFooter}>
      <p className={styles.Description}>
        {t('NAVIGATION.FOOTER.COPYRIGHT', { year: new Date().getFullYear() })}
      </p>
    </div>
  );
};

export default SecondaryFooter;
