import React, { useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import styles from './SearchButton.module.scss';
import Props from './SearchButton.props';
import SearchOverlay from '../SearchOverlay/SearchOverlay';
import { useFocus } from '@hooks/useFocus';

const SearchButton = ({ className }: Props) => {
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [inputRef, setinputFocus] = useFocus();

  const handleOpenOverlay = () => {
    setOverlayOpen(true);
    setinputFocus();
  };

  return (
    <>
      <div className={cn(styles.SearchButton, className)} onClick={() => handleOpenOverlay()}>
        <FontAwesomeIcon icon={faSearch} className={styles.Icon} />
      </div>

      <SearchOverlay
        inputFieldRef={inputRef}
        isOpen={isOverlayOpen}
        onCloseClick={() => setOverlayOpen(false)}
      />
    </>
  );
};

export default SearchButton;
