import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import styles from './MobileMenuItem.module.scss';
import Props from './MobileMenuItem.props';
import Card from '@components/ui/Card/Card';

const MobileMenuItem = ({
  selected,
  children,
  isSubMenuOpen,
  className,
  innerClassName,
  ...linkProps
}: Props) => {
  return (
    <li className={cn(styles.Wrapper, className)}>
      <Card
        className={cn(styles.MobileMenuItem, { [styles.selected]: selected }, innerClassName)}
        hasBackground
        {...linkProps}>
        {isSubMenuOpen !== undefined && (
          <FontAwesomeIcon
            icon={faChevronDown}
            className={cn(styles.Arrow, { [styles.Open]: isSubMenuOpen })}
          />
        )}
        {children}
      </Card>
    </li>
  );
};

export default MobileMenuItem;
