import { useIsPreview } from '@hooks/useIsPreview';
import React from 'react';
import styles from './Preview.module.scss';

export const Preview = () => {
  const isPreview = useIsPreview();

  if (!isPreview) {
    return null;
  }

  return (
    <div className={styles.Preview}>
      <a href="/api/exit-preview">Exit preview mode</a>
    </div>
  );
};
