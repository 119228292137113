import React, { useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons';

import { useSitelinks } from '@hooks/index';

import MobileMenuItem from '../MobileMenuItem/MobileMenuItem';
import MobileSubMenu from '../MobileSubMenu/MobileSubMenu';

import SearchForm from '@components/forms/SearchForm/SearchForm';
import { useStores } from '@models/RootStore/RootStore';

import styles from './MobileMenu.module.scss';
import Props from './MobileMenu.props';
import { NL_TRAVELOFFERS_DOMAIN } from '@constants/config';

const MobileMenu = ({ isOpen, triggerClose, className }: Props) => {
  const { t } = useTranslation();
  const siteLinks = useSitelinks();
  const router = useRouter();
  const { UserStore } = useStores();
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);

  const handleOpenSubMenu = (set: any, isOpen: boolean) => {
    setAccountMenuOpen(false);

    set(!isOpen);
  };

  const handleLogout = () => {
    UserStore.logout();
    router.replace('/');
  };

  const isDP = router.locale === 'nl-NL';

  return (
    <div className={cn(styles.MobileMenu, { [styles.Open]: isOpen }, className)}>
      <ul className={styles.Inner}>
        <MobileMenuItem
          href={`/${siteLinks?.destinations_overview?.[0]._slug}`}
          className={styles.MobileMenuItem}
          onClick={triggerClose}>
          {t('NAVIGATION.MENU.DESTINATIONS')}
        </MobileMenuItem>
        {!!siteLinks?.top_ten_lists?.[0]._slug && (
          <MobileMenuItem
            href={`/${siteLinks?.top_ten_lists?.[0]._slug}`}
            className={styles.MobileMenuItem}
            onClick={triggerClose}>
            {t('NAVIGATION.MENU.TOPLISTS')}
          </MobileMenuItem>
        )}
        {isDP && (
          <MobileMenuItem
            href={NL_TRAVELOFFERS_DOMAIN}
            className={styles.MobileMenuItem}
            onClick={triggerClose}>
            {t('NAVIGATION.MENU.TRAVELOFFERS')}
          </MobileMenuItem>
        )}
        {!!siteLinks?.travelstory_archive?.[0]._slug && (
          <MobileMenuItem
            href={`/${siteLinks?.travelstory_archive?.[0]._slug}`}
            className={styles.MobileMenuItem}
            onClick={triggerClose}>
            {t('NAVIGATION.MENU.TRAVELSTORIES')}
          </MobileMenuItem>
        )}
        {!!siteLinks?.wildlife?.[0]._slug && (
          <MobileMenuItem
            href={`/${siteLinks?.wildlife?.[0]._slug}`}
            className={styles.MobileMenuItem}
            onClick={triggerClose}>
            {t('NAVIGATION.MENU.WILDLIFE')}
          </MobileMenuItem>
        )}
        {!!siteLinks?.news_archive?.[0]._slug && (
          <MobileMenuItem
            href={`/${siteLinks?.news_archive?.[0]._slug}`}
            className={styles.MobileMenuItem}
            onClick={triggerClose}>
            {t('NAVIGATION.MENU.NEWS')}
          </MobileMenuItem>
        )}
        {UserStore.isAuthenticated ? (
          <MobileMenuItem
            onClick={() => handleOpenSubMenu(setAccountMenuOpen, isAccountMenuOpen)}
            isSubMenuOpen={isAccountMenuOpen}
            className={styles.MobileMenuItem}
            innerClassName={styles.Account}>
            <FontAwesomeIcon icon={faUserCircle} className={styles.AccountIcon} />
            {`${t('NAVIGATION.MENU.MY_ACCOUNT')}`}
            <MobileSubMenu
              isOpen={isAccountMenuOpen}
              links={[
                {
                  label: siteLinks?.my_account?.[0].title || '',
                  url: `/${siteLinks?.my_account?.[0]._slug}`,
                },
                {
                  label: siteLinks?.my_bucketlist?.[0].title || '',
                  url: `/${siteLinks?.my_bucketlist?.[0]._slug}`,
                },
                {
                  label: siteLinks?.my_visited?.[0].title || '',
                  url: `/${siteLinks?.my_visited?.[0]._slug}`,
                },
                {
                  label: t('DOMAIN.AUTHENTICATION.LOGOUT'),
                  onClick: handleLogout,
                },
              ]}
              className={styles.SubMenu}
              triggerClose={triggerClose}
            />
          </MobileMenuItem>
        ) : (
          <MobileMenuItem
            href={`/${siteLinks?.login_register?.[0]._slug}`}
            className={styles.MobileMenuItem}
            onClick={triggerClose}>
            <FontAwesomeIcon icon={faUserCircle} className={styles.AccountIcon} />
            {t('NAVIGATION.MENU.LOGIN')}
          </MobileMenuItem>
        )}

        <MobileMenuItem className={styles.MobileMenuItem} innerClassName={styles.SearchWrapper}>
          <SearchForm onSearch={() => triggerClose()} theme="basic" />
        </MobileMenuItem>
      </ul>
    </div>
  );
};

export default MobileMenu;
