import React from 'react';
import cn from 'classnames';

import { useNewsletter } from '@hooks/useNewsletter';

import Footer from '@components/navigation/Footer/Footer';
import Navbar from '@components/navigation/Navbar/Navbar';
import HeaderNewsletterSignup from '@components/ui/HeaderNewsletterSignup/HeaderNewsletterSignup';

import styles from './Layout.module.scss';
import Props from './Layout.props';
import { Preview } from '@components/ui/Preview/Preview';

const Layout = ({ children, paddings = true, selectedTab }: Props) => {
  const renderNewsletter = useNewsletter();

  return (
    <>
      <Preview />

      <Navbar selectedTab={selectedTab} />

      {renderNewsletter && <HeaderNewsletterSignup />}
      <div className={cn(styles.Content, { [styles.Paddings]: paddings })}>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
