import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import classes from './SearchInput.module.scss';
import Props from './SearchInput.props';

const SearchInput = ({ value, onChange, onSubmit, theme = 'default', className }: Props) => {
  const { t } = useTranslation();

  const submitListener = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') onSubmit();
  };

  return (
    <div className={cn(classes.SearchInput, classes[theme], className)}>
      <div className={cn(classes.Inner, { [classes.valid]: !!value && value.length > 0 })}>
        <input
          name="search"
          value={value}
          placeholder={t('GENERIC.SEARCH')}
          onChange={(e) => onChange(e.target.value)}
          onKeyUp={submitListener}
        />
        <FontAwesomeIcon icon={faSearch} className={classes.Icon} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default SearchInput;
