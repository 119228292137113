import React from 'react';
import cn from 'classnames';
import NextLink from 'next/link';

import Props from './Link.props';
import styles from './Link.module.scss';

const Link = ({
  href,
  label,
  onClick,
  onHover,
  onFocus,
  children,
  className,
  openInNewTab,
  noIndex,
  external,
}: Props) => {
  const hasLink = !!href && !!onClick;
  const classes = cn(styles.Link, { [styles.HasLink]: hasLink }, className);

  const isExternal =
    external !== false && (external || href?.startsWith('https://') || href?.startsWith('http://'));

  if (href) {
    if (isExternal) {
      return (
        <a
          href={href}
          className={classes}
          target="_blank"
          rel="noopener noreferrer nofollow"
          onClick={onClick}
          onMouseOver={onHover}
          onFocus={onFocus}
          aria-label={label}>
          {children}
        </a>
      );
    }

    return (
      <NextLink href={href} prefetch={false}>
        <a
          className={classes}
          target={openInNewTab ? '_blank' : ''}
          rel={noIndex ? 'noopener noreferrer nofollow' : ''}
          onClick={onClick}
          onMouseOver={onHover}
          onFocus={onFocus}
          aria-label={label}>
          {children}
        </a>
      </NextLink>
    );
  } else {
    return (
      <div
        onClick={onClick}
        onMouseOver={onHover}
        onFocus={onFocus}
        className={classes}
        aria-label={label}>
        {children}
      </div>
    );
  }
};

export default Link;
