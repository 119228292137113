import React from 'react';
import cn from 'classnames';

import FooterList from '../FooterList/FooterList';
import FooterSharing from '../FooterSharing/FooterSharing';

import styles from './PrimaryFooter.module.scss';
import Props from './PrimaryFooter.props';

const PrimaryFooter = ({ lists }: Props) => {
  return (
    <div className={styles.PrimaryFooter}>
      <div className={cn(styles.Inner, 'container')}>
        <div className={styles.Lists}>
          {lists.map((list, idx) => (
            <FooterList key={idx} {...list} className={styles.List} />
          ))}
        </div>
        <div className={styles.Sharing}>
          <FooterSharing />
        </div>
      </div>
    </div>
  );
};

export default PrimaryFooter;
