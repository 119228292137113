import React from 'react';
import cn from 'classnames';

import styles from './TextLink.module.scss';
import Props from './TextLink.props';
import { Link } from '../../containers';

const TextLink = ({ theme = 'primary', className, ...linkProps }: Props) => {
  const classes = cn(styles.TextLink, styles[theme], className);

  return <Link className={classes} {...linkProps} />;
};

export default TextLink;
