import React from 'react';

import PrimaryFooter from '../PrimaryFooter/PrimaryFooter';
import SecondaryFooter from '../SecondaryFooter/SecondaryFooter';

import styles from './Footer.module.scss';

import { useFooterLinks } from '@hooks/useFooterLinks';

const Footer = () => {
  const footerLinks = useFooterLinks();

  const showLeftColumn =
    footerLinks?.left_column_content && footerLinks.left_column_content.length > 0;
  const showRightColumn =
    footerLinks?.right_column_content && footerLinks.right_column_content.length > 0;

  const lists = [
    {
      title: showLeftColumn ? footerLinks?.left_column_title || '' : '',
      links:
        footerLinks?.left_column_content.map((item) => ({
          label: item.title || '',
          url: item._slug,
        })) || [],
    },
    {
      title: showRightColumn ? footerLinks?.right_column_title || '' : '',
      links:
        footerLinks?.right_column_content.map((item) => ({
          label: item.title || '',
          url: item._slug,
        })) || [],
    },
  ];

  return (
    <footer className={styles.Footer}>
      <PrimaryFooter lists={lists} />
      <SecondaryFooter />
    </footer>
  );
};

export default Footer;
