import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import styles from './FooterSharing.module.scss';
import Props from './FooterSharing.props';
import { useRouter } from 'next/router';

const FooterSharing = ({ className }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const [hovering, setHovering] = useState('');

  const isDP = locale === 'nl-NL';

  const platforms = [
    {
      name: 'Facebook',
      icon: faFacebookF,
      link: isDP
        ? `https://nl-nl.facebook.com/droomplekken.nl`
        : `https://www.facebook.com/amazingplacescom/`,
      color: '#4267B2',
    },
    {
      name: 'Instagram',
      icon: faInstagram,
      link: `https://www.instagram.com/the.bucketlist.traveler/`,
      color: '#fd1d1d',
    },
    {
      name: 'Youtube',
      icon: faYoutube,
      link: `https://www.youtube.com/channel/UCvD2qXjAQ0sBSqzdzvVvHcA`,
      color: '#fd1d1d',
    },
  ];

  if (isDP) {
    platforms.push({
      name: 'Twitter',
      icon: faTwitter,
      link: `https://twitter.com/droomplekken`,
      color: '#1DA1F2',
    });
  }

  return (
    <div className={cn(styles.FooterSharing, className)}>
      <p className={styles.Title}>{t('GENERIC.FOLLOW_DP')}</p>
      <div className={styles.Platforms}>
        {platforms.map((p) => {
          return (
            <a key={p.link} href={p.link} className={styles.Platform}>
              <FontAwesomeIcon
                icon={p.icon}
                onMouseEnter={() => setHovering(p.link)}
                onMouseLeave={() => setHovering('')}
                style={{ color: hovering === p.link ? p.color : '#fff' }}
                className={styles.Icon}
                fixedWidth
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default FooterSharing;
