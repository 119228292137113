import React, { ReactNode } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from '../../containers';

import Props from './Button.props';
import styles from './Button.module.scss';
import Spinner from '../Spinner/Spinner';

const Button = ({
  onPress,
  children,
  href,
  className,
  icon,
  disabled,
  isLoading,
  iconPosition,
  theme = 'primary',
  size = 'md',
  type,
  ...otherProps
}: Props) => {
  const containerClasses = cn(styles.Button, styles[size], styles[`${theme}`], className);

  const LeftIconPosition = ({ icon, children }: Partial<Props>) => {
    if (!icon) return null;

    return (
      <>
        <FontAwesomeIcon icon={icon} className={cn(styles.Icon, styles.IconLeft)} />
        {children}
      </>
    );
  };

  const RightIconPosition = ({ icon, children }: Partial<Props>) => {
    if (!icon) return null;

    return (
      <>
        {children}

        <FontAwesomeIcon icon={icon} className={cn(styles.Icon, styles.IconRight)} />
      </>
    );
  };

  const ContentWrapper = ({
    children,
    isLoading,
  }: {
    children: ReactNode;
    isLoading?: boolean;
  }) => {
    return (
      <div className={cn(styles.ContentWrapper, { [styles.Loading]: isLoading })}>{children}</div>
    );
  };

  const ButtonContent = ({ iconPosition, icon, children }: Partial<Props>) => {
    if (!icon) return <>{children}</>;

    if (iconPosition === 'left') {
      return <LeftIconPosition icon={icon} children={children} />;
    } else {
      return <RightIconPosition icon={icon} children={children} />;
    }
  };

  const Loader = ({ isLoading }: any) => {
    return (
      <div className={cn(styles.Loader, { [styles.Loading]: isLoading })}>
        <Spinner size={18} color="#fff" />
      </div>
    );
  };

  const onClickButton = (
    <button
      disabled={disabled}
      type={type}
      className={containerClasses}
      onClick={onPress}
      {...otherProps}>
      <ContentWrapper isLoading={isLoading}>
        <ButtonContent iconPosition={iconPosition} icon={icon} children={children} />
      </ContentWrapper>
      <Loader isLoading={isLoading} />
    </button>
  );

  const linkButton = href ? (
    <Link href={href} className={containerClasses}>
      <ContentWrapper isLoading={isLoading}>
        <ButtonContent iconPosition={iconPosition} icon={icon} children={children} />
      </ContentWrapper>
      <Loader isLoading={isLoading} />
    </Link>
  ) : null;

  return href ? linkButton : onClickButton;
};

export default Button;
