import React, { useState } from 'react';
import cn from 'classnames';
import Image from 'next/image';
import Hamburger from 'hamburger-react';
import { useTranslation } from 'react-i18next/';

import { useLocale } from '@hooks/useLocale';

import Menu from '../Menu/Menu';
import MobileMenu from '../MobileMenu/MobileMenu';

import styles from './Navbar.module.scss';
import Props from './Navbar.props';
import Link from 'next/link';

const Navbar = ({ selectedTab, ...menuProps }: Props) => {
  const { t } = useTranslation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  let logo = require('@assets/images/logo.png');

  switch (useLocale()) {
    case 'nl-NL':
      logo = require('@assets/images/logo.png');
      break;
    case 'en-GB':
      logo = require('@assets/images/Logo-AmazingPlaces.png');
      break;
  }

  return (
    <div className={styles.Navbar}>
      <div className={cn(styles.Inner, 'container')}>
        <Link href="/">
          <a className={styles.Logo}>
            <Image
              src={logo}
              alt={t('GENERIC.BACK_TO_HOME')}
              quality={60}
              layout="fill"
              objectFit="contain"
              priority
            />
          </a>
        </Link>
        <Menu {...menuProps} className={styles.Menu} selectedTab={selectedTab} />
        <MobileMenu
          {...menuProps}
          isOpen={isMobileMenuOpen}
          triggerClose={() => setMobileMenuOpen(false)}
          className={styles.MobileMenu}
        />
        <div className={styles.HamburgerWrapper}>
          <Hamburger toggled={isMobileMenuOpen} toggle={setMobileMenuOpen} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
