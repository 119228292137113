import React from 'react';
import cn from 'classnames';

import styles from './LargeSubMenuList.module.scss';
import Props from './LargeSubMenuList.props';
import TextLink from '@components/ui/TextLink/TextLink';

const LargeSubMenuList = ({ title, items, overviewLink, triggerClose, className }: Props) => {
  return (
    <div className={cn(styles.LargeSubMenuList, className)}>
      <TextLink href={title.url} onClick={triggerClose} className={styles.Title}>
        {title.label}
      </TextLink>
      <ul className={styles.Content}>
        {items.map((item) => (
          <li key={item.url} className={styles.ListItem}>
            <TextLink href={item.url} onClick={triggerClose}>
              {item.label}
            </TextLink>
          </li>
        ))}
      </ul>
      {!!overviewLink && (
        <TextLink
          noIndex
          href={overviewLink.url}
          onClick={triggerClose}
          className={styles.OverviewLink}>
          {overviewLink.label}
        </TextLink>
      )}
    </div>
  );
};

export default LargeSubMenuList;
