import React from 'react';
import cn from 'classnames';

import classes from './CheckboxInput.module.scss';
import Props from './CheckboxInput.props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

const CheckboxInput = ({ children, name, className, value = false, ...otherProps }: Props) => {
  return (
    <div className={cn(classes.CheckboxInput, className)}>
      <input
        id={name}
        name={name}
        className={cn(classes.Checkbox, { [`${classes.Checked}`]: value })}
        type="checkbox"
        {...otherProps}
      />
      <label htmlFor={name} className={classes.Label}>
        {children}
        <FontAwesomeIcon icon={faCheck} className={classes.Icon} />
      </label>
    </div>
  );
};

export default CheckboxInput;
