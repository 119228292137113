import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import styles from './HeaderNewsletterSignup.module.scss';
import { NewsletterSignupFormWrapper } from '@components/forms';
import { useNewsHeaderIsOpen } from './internal/hooks/useNewsHeaderIsOpen';

const HeaderNewsletterSignup = () => {
  const { t } = useTranslation();
  const { isOpen, setOpen } = useNewsHeaderIsOpen(4000);

  return (
    <div className={cn(styles.HeaderNewsletterSignup, { [styles.Open]: isOpen })}>
      <div className={styles.Icon} onClick={() => setOpen((wasOpen) => !wasOpen)}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faChevronLeft} />
      </div>
      <div className={styles.MobileIcon} onClick={() => setOpen((wasOpen) => !wasOpen)}>
        <FontAwesomeIcon className={styles.Mobile} icon={faEnvelope} />
      </div>
      <div className={styles.ClickableBar} onClick={() => setOpen(true)} />
      <p className={styles.ClosedLabel}>{t('DOMAIN.NEWSLETTER.TITLE')}</p>
      <div className={styles.Content}>
        <div className={styles.MobileCloseIcon} onClick={() => setOpen((wasOpen) => !wasOpen)}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faChevronLeft} />
        </div>
        <p className={styles.Title}>{t('DOMAIN.NEWSLETTER.TITLE')}</p>
        <p className={styles.Description}>{t('DOMAIN.NEWSLETTER.DESCRIPTION')}</p>
        <NewsletterSignupFormWrapper theme="secondary" onSubmit={() => setOpen(false)} />
      </div>
    </div>
  );
};

export default HeaderNewsletterSignup;
