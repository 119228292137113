import axios from 'axios';
import { useEffect, useState } from 'react';

export const useIsPreview = (initialValue = false) => {
  const [isPreview, setPreview] = useState(initialValue);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('/api/ispreview/');

        setPreview(data?.isPreview === true);
      } catch {
        setPreview(false);
      }
    })();
  }, []);

  return isPreview;
};
